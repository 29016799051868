import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import CardLoadingComponent from "../Loading/CardLoadingComponent";
import NoMoreItemComponent from "../Loading/NoMoreItemComponent";
import CategoryItemComponent from "../Categories/CategoryItemComponent";
import ProductItem from "./ProductItem";
import ProductUseIdPlayer from "./ProductUseIdPlayer";
import ProductCodePay from "./ProductCodePay";
import ProductFree from "./ProductFree";

export default function ProductDetails() {
    const productId = useParams().id
    const [loading, setLoading] = useState(false)
    const [product, setProduct] = useState(null)
    const [error, setError] = useState('')

    const getProducts = async () => {
        setLoading(true)
        try {
            const response = await baseApi(`/products/${productId}`)
            setProduct(response.data?.product)
        } catch (e) {
            setError(e.response?.data?.error)
        }
        setLoading(false)

    }
    useEffect(() => {
        getProducts()
    }, [])

    if (loading) {
        return (
            <div className={'container p-5'}>
                <div className="row justify-content-center">
                    <CardLoadingComponent/>
                    <CardLoadingComponent/>
                    <CardLoadingComponent/>
                    <CardLoadingComponent/>
                </div>

            </div>
        )
    } else if (product == null && error.length === 0) {

        return (
            <NoMoreItemComponent text={'المنتج غير موجود '}/>
        )
    } else if (!loading && error?.length > 0) {
        return (
            <NoMoreItemComponent text={error}/>
        )
    } else {
        return (
            <div className={'container py-5'}>
                <div className="row justify-content-center">
                    <div className="col-md-8 justify-content-center d-flex">
                        <div className="card-login">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="logo-container">
                                    <img src={product.img} className={'logo-auth'} alt=""/>
                                </div>
                                <div className="product-info">
                                    <div className="d-flex flex-column align-items-center  ">
                                        <div className="d-flex flex-column ">
                                        <span><i className="fa fa-caret-left fa-lg text-primary"></i> <span
                                            className={'text-error fs-6'}>{product.name}</span></span>
                                            <span className={''}>{product.description}</span>
                                        </div>
                                        <span
                                            className={'text-primary border border-1 p-1 rounded'}>{product.price.toFixed(2)} $</span>
                                    </div>
                                </div>
                                {product.type === 'code' ? <ProductCodePay product={product}/> : null}
                                {!product.is_free && product.type !== 'code' ? <ProductUseIdPlayer product={product}/> : null}
                                {product.is_free && product.type !== 'code' ? <ProductFree product={product}/> : null}

                            </div>
                        </div>

                    </div>

                </div>


            </div>
        )

    }

}
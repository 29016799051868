import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import CardLoadingComponent from "../Loading/CardLoadingComponent";
import NoMoreItemComponent from "../Loading/NoMoreItemComponent";
import BillItem from "./BillItem";

export default function BillsSection() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [page, setPage] = useState(1)
    const [orders, setOrders] = useState([])
    const [next, setNext] = useState(null)
    const [prev, setPrev] = useState(null)
    const [status, setStatus] = useState('')
    const getOrders = async () => {
        setLoading(true)
        setError(null)
        setOrders([])
        try {
            const response = await baseApi(`/bills?page=${page}&status=${status}`);
            setOrders(response.data?.orders)
            setNext(response.data?.next)
            setPrev(response.data?.back)
            if (response.data?.orders?.length === 0) {
                setError('لا يوجد بيانات لعرضها')
            }
        } catch (e) {
            setError(e.response.data?.error ?? e.response.data?.msg??'لا يوجد بيانات لعرضها')
        }
        setLoading(false)

    }
    const changePage = (num) => {

        if (page >0 && num > 0) {
            setPage(num)
        }
    }
    const changeStatus = (state = '') => {
        setPage(1)
        setStatus(state)
    }
    useEffect(() => {
        getOrders()
    }, [page, status])

    return (
        <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-md-2">
                   <div className="d-flex flex-column gap-2 my-3">
                       <button className={`btn btn-sm btn-outline-secondary ${status===''?'active':null}`} onClick={() => changeStatus('')}>الكل</button>
                       <button className={`btn btn-sm btn-outline-info ${status==='pending'?'active':null}`} onClick={() => changeStatus('pending')}>بالإنتظار</button>
                       <button className={`btn btn-sm btn-outline-success ${status==='complete'?'active':null}`} onClick={() => changeStatus('complete')}>مقبول</button>
                       <button className={`btn btn-sm btn-outline-warning ${status==='canceled'?'active':null}`} onClick={() => changeStatus('canceled')}>مرفوض</button>
                   </div>
                </div>
                {loading ? <><CardLoadingComponent/> <CardLoadingComponent/> <CardLoadingComponent/></> : null}
                <div className="col-md-10">

                    {error?.length > 0 ? <NoMoreItemComponent text={error}/> : null}
                    {orders?.length>0?orders?.map((item)=>{
                        return <BillItem order={item} key={item.id}/>
                    }):null}
                </div>
                <div className="col-12 text-center">
                    {prev!=null? <button className={'btn btn-sm btn-outline-danger mx-2'} onClick={()=>changePage(page-1)}>التالي</button>:null}
                    {next!=null?<button className={'btn btn-sm btn-outline-danger mx-2'} onClick={()=>changePage(page+1)}>السابق</button>:null}


                </div>
            </div>
        </div>
    )

}
import {Link, useNavigate} from "react-router-dom";

import logo from '../../assets/imgs/slide2.png'
import {useState} from "react";
import {useAuthStore} from "../../store/user-store";
import {baseApi} from "../../api/axios";


export default function LoginComponent() {
    const redirect = useNavigate()
    const [loading, setLoading] = useState(false)
   const [scure, setScure] = useState('password')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const authStore = useAuthStore()

     const changeScure=()=>{
        setScure(scure=='password'?'text':'password')
    }

    const login = async () => {
        setLoading(true)
        setError('')
        const data = new FormData()
        data.append('username', email)
        data.append('password', password)
        try {
            const response = await baseApi.post('/login', data)
            localStorage.setItem('user', JSON.stringify(response.data?.user))
            localStorage.setItem('token', response.data?.token)
            authStore.setUser(response.data?.user)
            redirect('/')
            // authStore.setUser(,)

        } catch (e) {
            setError(e.response.data?.error)
        }
        setLoading(false)

    }
    return (
        <div className={'container'}>
            <div className="row justify-content-center align-items-center">

                <div className="col-md-8 d-flex justify-content-center align-items-center">
                    <div className="card-login">
                        <div className="d-flex flex-column justify-content-center align-items-center card-auth"
                             style={{flex: '1 0 auto'}}>
                            <div className={'logo-container'}><img className={'logo-auth'} src={authStore.settings?.logo??logo} alt=""/></div>


                            <h2 className={'text-primary my-4'}>تسجيل الدخول</h2>


                            <input type="email" onChange={(e) => setEmail(e.target.value)} value={email}
                                   className="input-form input my-2" placeholder={'البريد الإلكتروني'}
                                   autoComplete={'off'}/>
                            <div className={'position-relative password-container w-100'}>
                                <input type={scure} onChange={(e) => setPassword(e.target.value)}
                                       className="input-form input my-2" placeholder={'كلمة المرور'}
                                       autoComplete={'new-password'}/>
                                <i onClick={changeScure} className="fa fa-eye position-absolute text-primary"></i>
                            </div>
                            {error.length > 0 ? <span className=" my-2 text-error d-block ">{error}</span> : null}
                            {!loading ? <button onClick={login} className="btn d-block btn-sm btn-buy ">دخول</button> :
                                <button className="btn d-block btn-sm submit-btn ">جاري المعالجة</button>}
                            <Link to={'/register'} className="btn d-block btn-sm text-black my-2">ليس لدي حساب</Link>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
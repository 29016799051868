import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import CardLoadingComponent from "../Loading/CardLoadingComponent";
import NoMoreItemComponent from "../Loading/NoMoreItemComponent";
import CategoryItemComponent from "../Categories/CategoryItemComponent";
import ProductItem from "./ProductItem";
import ProductUseIdPlayer from "./ProductUseIdPlayer";
import ProductCodePay from "./ProductCodePay";
import {useAuthStore} from "../../store/user-store";
import {toast} from "react-toastify";
import LoadingComponent from "../Loading/LoadingComponent";

export default function ProductFree({product}) {
    const [loading, setLoading] = useState(false)
    const [loadingCheck, setLoadingCheck] = useState(false)
    const [error, setError] = useState('')
    const [PlayerId, setPlayerId] = useState('')
    const [PlayerName, setPlayerName] = useState('')
    const [PlayerNote, setPlayerNote] = useState('')
    const [qty, setQty] = useState(product.min_qty)
    const [totalPrice, setTotalPrice] = useState(product.price)
    const [PlayerIdLabel, setPlayerIdLabel] = useState('')
    const [PlayerNameLabel, setPlayerNameLabel] = useState('')
    const authStore = useAuthStore();
    const redirect = useNavigate()
    const validate = () => {
        setError('')
        switch (product.type) {
            case 'id':
                if (PlayerId.length === 0) {
                    setError('رقم اللاعب مطلوب')
                    return false;
                }

                break
            case 'account':
                if (PlayerId.length === 0) {
                    setError('اسم المستخدم مطلوب')
                    return false;
                }
                if (PlayerName.length === 0) {
                    setError('كلمة المرور مطلوبة')
                    return false;
                }
                break
            case 'phone':
                if (PlayerId.length === 0) {
                    setError('رقم الهاتف  مطلوب')
                    return false;
                }

                break
            case 'url':
                if (PlayerId.length === 0) {
                    setError('رابط الصفحة  مطلوب')
                    return false;
                }

                break
        }

        if (qty < product.min_qty || qty > product.max_qty) {

            setError(`يجب ان تكون الكمية بين ${product.min_qty} و  ${product.max_qty}`)
            return false;
        }
        return true;
    }
    const submit = async () => {
        if (validate()) {
            setLoading(true)
            const formData = new FormData();
            formData.append('product_id', product.id)
            formData.append('player_id', PlayerId)
            formData.append('player_name', PlayerName)
            formData.append('note', PlayerNote)
            formData.append('qty', qty)
            try {
                const response = await baseApi.post('/bills', formData)
                if (response.data?.user) {
                    localStorage.setItem('user', JSON.stringify(response.data?.user))
                    authStore.setUser(response.data?.user);
                }
                toast.success('تم شراء المنتج بنجاح')
                 redirect('/bills')
            } catch (e) {
                setError(e.response.data?.msg ?? e.response.data?.error)
            }
            setLoading(false)
        }

    }
    const checkPlayer = async () => {
        setPlayerName('')
        setLoadingCheck(true)
        try {
            const data = new FormData()
            data.append('product_id', product.id)
            data.append('player_id', PlayerId)
            const response = await baseApi.post('/getNickName', data)
            if (response.data?.name) {

                if(response.data?.name.toString()?.length===0){
                    toast.error('يرجى التأكد من ID  الحساب')
                }else{
                    setPlayerName(response.data?.name)
                }

            }else{
                toast.error('يرجى التأكد من ID  الحساب')
            }
        } catch (e) {
            toast.error(e.response.data?.msg)
        }
        setLoadingCheck(false)
    }

    useEffect(() => {

        switch (product.type) {
            case 'id':
                setPlayerIdLabel('رقم اللاعب')
                setPlayerNameLabel('اسم اللاعب')
                break
            case 'account':
                setPlayerIdLabel('اسم المستخدم')
                setPlayerNameLabel('كلمة المرور')
                break
            case 'phone':
                setPlayerIdLabel('رقم الهاتف')
                setPlayerNameLabel('تأكيد رقم الهاتف')
                break
            case 'url':
                setPlayerIdLabel('رابط الصفحة')
                setPlayerNameLabel('تأكيد رقم الهاتف')
                break
        }
    }, [])
    useEffect(() => {
        setTotalPrice(qty * product.unit_price)
    }, [qty])
    return (

        <div className=" my-1 p-1">
            <div className="d-flex flex-column justify-content-center   ">

                <div className="d-flex align-items-center justify-content-start gap-1">
                    <input type="text" onChange={(e) => {
                        setPlayerId(e.target.value)
                    }} id={'PlayerId'} className={'form-control input w-75'} placeholder={PlayerIdLabel}/>
                    <span className={`check-name ${loadingCheck ? 'rotate' : ''}`} onClick={checkPlayer}><svg
                        xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                        fill="currentColor"
                        className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
</svg></span>
                </div>

                {product.type === 'url' || product.type === 'phone' ? null :

                    !product.is_check_player ?
                        <input type="text" onChange={(e) => {
                            setPlayerName(e.target.value)
                        }} id={'PlayerName'} className={'form-control input'} placeholder={PlayerNameLabel}/> :
                        <div className={'response-player'}>الاسم : {PlayerName}</div>
                }
                <span className={'small'}>الكمية</span>
                <div className="d-flex align-items-center gap-1">
                    <input type="number" value={qty} onChange={(e) => setQty(e.target.value)} id="Qty"
                           className={'form-control input w-50'}
                           placeholder={'الكمية'}/>
                    <span>{totalPrice.toFixed(2)} $</span>
                </div>
                <input type="text" onChange={(e) => {
                    setPlayerNote(e.target.value)
                }} id={'Note'} className={'form-control input'} placeholder={'ملاحظات'}/>
                {error.length ? <span className={'text-error'}>{error}</span> : null}
                {authStore.user?.id ? <div className="form-group my-3">
                    {loading ? <LoadingComponent/> : <button onClick={submit} className={'btn-buy'}>شراء</button>}
                </div> : <div className="text-center w-100 my-3">
                    <Link to={'/login'} className={'btn-buy'}>تسجيل الدخول</Link>
                </div>}
            </div>
        </div>

    )

}

import CardLoadingComponent from "../Loading/CardLoadingComponent";
import {useEffect, useState} from "react";
import NoMoreItemComponent from "../Loading/NoMoreItemComponent";
import {baseApi} from "../../api/axios";
import CategoryItemComponent from "./CategoryItemComponent";
import {Link, useParams} from "react-router-dom";

export default function SectionSubCategoryComponent() {
    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [error, setError] = useState('')
    const categoryId = useParams().id;
    const getCategories = async () => {
        setError('')
        setLoading(true)
        try {
            const response = await baseApi(`/categories/${categoryId}`);
            setCategories(response.data?.categories)
        } catch (e) {
            setError(e.response?.data?.msg)
        }
        setLoading(false)
    }
    useEffect(() => {
        getCategories()
    }, [])

    if (loading) {
        return (
            <div className={'container p-5'}>
                <div className="row justify-content-center">
                    <CardLoadingComponent/>
                    <CardLoadingComponent/>
                    <CardLoadingComponent/>
                    <CardLoadingComponent/>
                </div>

            </div>
        )
    } else if (categories.length === 0 && error.length === 0) {

        return (
            <NoMoreItemComponent text={'لا يوجد أقسام '}/>
        )
    } else if (!loading && error.length > 0) {

        return (
            <NoMoreItemComponent text={error}/>
        )
    } else {
        return (
            <div className={'container p-5'}>
                <div className="row justify-content-center">
                    {categories?.map((category) => {
                        return <CategoryItemComponent key={category.id} category={category} link={`/products/${category.id}`}/>
                    })}
                </div>
            </div>
        )

    }

}
import MarqueeComponent from "../components/Marquee/MarqueeComponent";
import SliderComponent from "../components/Slider/SliderComponent";
import SectionCategoryComponent from "../components/Categories/SectionCategoryComponent";


export default function HomePage() {




        return (

            <>
                <SliderComponent/>
                <MarqueeComponent/>
                <SectionCategoryComponent/>
            </>
        )


}
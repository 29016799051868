import {Link, useNavigate} from "react-router-dom";

import logo from '../../assets/imgs/slide2.png'
import {useState} from "react";
import {useAuthStore} from "../../store/user-store";
import {baseApi} from "../../api/axios";


export default function RegisterComponent() {
    const redirect = useNavigate()
    const [loading, setLoading] = useState(false)
    const [scure, setScure] = useState('password')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [username, setUsername] = useState('')
    const [address, setAddress] = useState('')
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState(null)
    const authStore = useAuthStore()
    const changeScure=()=>{
        setScure(scure=='password'?'text':'password')
    }
    const login = async () => {
        setErrors([])
        setLoading(true)
        const data = new FormData()
        data.append('email', email)
        data.append('password', password)
        data.append('username', username)
        data.append('address', address)
        data.append('name', name)
        data.append('phone', phone)
        try {
            const response = await baseApi.post('/register', data)
            localStorage.setItem('user', JSON.stringify(response.data?.user))
            localStorage.setItem('token', response.data?.token)
            authStore.setUser(response.data?.user)
            redirect('/')
            // authStore.setUser(,)

        } catch (e) {
            setErrors(e.response.data?.errors)
        }
        setLoading(false)

    }
    return (
        <div className={'container'}>
            <div className="row justify-content-center align-items-center">

                <div className="col-md-8 d-flex justify-content-center align-items-center">
                    <div className="card-login">
                        <div className="row justify-content-center align-items-center">

                                <h2 className={'text-error fs-4 text-center my-3'}>تسجيل حساب جديد</h2>
                            <div className="col-md-8">

                                <div className="d-flex flex-column justify-content-center align-items-center card-auth"
                                     style={{flex: '1 0 auto'}}>
                                    <div className={'logo-container'}><img className={'logo-auth'} src={authStore.settings?.logo??logo} alt=""/></div>

                                    <input type="text" onChange={(e) => setName(e.target.value)}
                                           className="input-form input my-1" placeholder={'الاسم'} autoComplete={'off'}/>
                                    {errors!=null && errors.name?.length>0?<span className={'text-error align-right'}>{errors.name}</span>:null}
                                    <input type="email" onChange={(e) => setEmail(e.target.value)}
                                           className="input-form input my-1" placeholder={'البريد الإلكتروني'}
                                           autoComplete={'new-email'}/>
                                    {errors!=null && errors.email?.length>0?<span className={'text-error align-right'}>{errors.email}</span>:null}
                                    <input type="text" onChange={(e) => setUsername(e.target.value)}
                                           className="input-form input my-1" placeholder={'اسم المستخدم'}
                                           autoComplete={'new-username'}/>
                                    {errors!=null && errors.username?.length>0?<span className={'text-error align-right'}>{errors.username}</span>:null}
                                    <input type="text" onChange={(e) => setPhone(e.target.value)}
                                           className="input-form input my-2" placeholder={'رقم الهاتف'} autoComplete={'off'}/>
                                    {errors!=null && errors.phone?.length>0?<span className={'text-error align-right'}>{errors.phone}</span>:null}
                                    <input type="text" onChange={(e) => setAddress(e.target.value)}
                                           className="input-form input my-2" placeholder={'العنوان'} autoComplete={'off'}/>
                                    {errors!=null && errors.address?.length>0?<span className={'text-error align-right'}>{errors.address}</span>:null}
                                   <div className={'position-relative password-container w-100'}>
                                       <input type={scure} onChange={(e) => setPassword(e.target.value)}
                                              className="input-form input my-2" placeholder={'كلمة المرور'}
                                              autoComplete={'new-password'}/>
                                       <i onClick={changeScure} className="fa fa-eye position-absolute text-primary"></i>
                                   </div>
                                    {errors!=null && errors.password?.length>0?<span className={'text-error align-right'}>{errors.password}</span>:null}
                                    {!loading ? <button onClick={login} className="btn d-block btn-sm btn-buy my-1 ">تسجيل
                                            جديد</button> :
                                        <button className="btn d-block btn-sm btn-buy ">جاري المعالجة</button>}
                                    <Link to={'/login'} className="btn d-block btn-sm dark-btn my-2"> لدي حساب
                                        بالفعل</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
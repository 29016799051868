import {Link, useNavigate} from "react-router-dom";
import {useAuthStore} from "../../store/user-store";

import {useEffect} from "react";


export default function Layout({children}) {
    const auth_store = useAuthStore()
    const redirect = useNavigate()
    const logOut = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        auth_store.logOut()
        redirect('/')
    }
    useEffect(() => {
        document.title = auth_store.settings?.name || 'Mazaya'
        if (auth_store.settings?.logo) {
            const link = document.querySelector("link[rel~='icon']");
            if (link) {
                link.href = auth_store.settings.logo;
            }
        }
    }, [auth_store.settings])

    return (

        <section className={' position-relative bg-bg'} data-theme={auth_store.theme}>
            {auth_store.user?.id ?
                <div className={'total_balance bg-gradient-red'}>{auth_store.user.balance.toFixed(2)} $</div> : null}
            <nav className="navbar main-nav-bar fixed-top bg-bg shadow" dir={'ltr'}>
                <div className="container-fluid">
                    <Link to={'/'} className="navbar-brand text-black">{auth_store.settings?.logo?.length>0?<img src={auth_store.settings?.logo} className={'logo-nav'} alt=""/>:null}<span>{auth_store.settings?.name}</span> </Link>
                    <span onClick={() => auth_store.setTheme(auth_store.theme === "light" ? 'dark' : 'light')}
                          className={`toggle-theme ${auth_store.theme === "dark" ? 'active' : null} `}></span>

                    <button className="btn bg-transparent  " type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i
                        className={'fa fa-bars fa-lg text-text'}/>
                    </button>
                </div>
            </nav>

            {/* Start SideBar */}
            <div className="offcanvas offcanvas-end bg-bg sidebar-width" tabIndex="-1" id="offcanvasRight"
                 aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header bg-bg shadow">
                    <div className="d-flex justify-content-between align-items-center flex-grow-1 ">
                        <h5 className="offcanvas-title text-text flex-grow-1"
                            id="offcanvasRightLabel"> {auth_store.user ? <span>
                            <img className={'group-img'} src={auth_store.user?.avatar} alt=""/>
                            <span>{auth_store.user?.name}</span> <span dir={'ltr'}
                                                                       className="text-primary">$ {auth_store.user?.balance.toFixed(2)} </span></span> :
                            <span>{auth_store.settings?.name}</span>}</h5>
                        <button type="button" className="bg-transparent border-0 text-secondary"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close">
                            <i className="fa fa-close text-text"></i>
                        </button>
                    </div>
                </div>
                <div className="offcanvas-body bg-bg shadow">
                    <ul className={'nav flex-column'} data-bs-dismiss="offcanvas"
                        aria-label="Close">
                        { auth_store.user?.level==='delegate' ?  <li className={'nav-item my-2 border-bottom border-1'}>
                            <a className={'nav-link  text-text'} target={'_blank'} href={'https://store.mazaya-online.com/dashboard'}><i
                                className="fa fa-dashboard text-primary mx-2"/>لوحة التحكم</a>
                        </li> : null}
                        { auth_store.user?.level==='admin' ?  <li className={'nav-item my-2 border-bottom border-1'}>
                            <a className={'nav-link  text-text'} target={'_blank'} href={'https://store.mazaya-online.com/admin'}><i
                                className="fa fa-dashboard text-primary mx-2"/>لوحة التحكم</a>
                        </li> : null}
                        <li className={'nav-item border-bottom border-1'}>

                            <Link className={'nav-link  text-text'} to={'/'}> <i
                                className="fa fa-home text-primary mx-2"/>الرئيسية</Link>
                        </li>
                        {auth_store.user !== null ? <li className={'nav-item my-2 border-bottom border-1'}>
                            <Link className={'nav-link  text-text'} to={'/profile'}><i
                                className="fa fa-user-cog text-primary mx-2"/>الملف الشخصي</Link>
                        </li> : null}
                        {auth_store.user === null ? <li className={'nav-item my-2 border-bottom border-1'}>
                            <Link className={'nav-link  text-text'} to={'/login'}><i
                                className="fa fa-right-to-bracket text-primary mx-2"/>تسجيل الدخول</Link>
                        </li> : null}
                        {auth_store.user === null ? <li className={'nav-item my-2 border-bottom border-1'}>
                            <Link className={'nav-link  text-text'} to={'/register'}><i
                                className="fa fa-id-card text-primary mx-2"/>تسجيل جديد</Link>
                        </li> : null}


                        {auth_store.user?.id ?  <li className={'nav-item my-2 border-bottom border-1'}>
                            <Link className={'nav-link  text-text'} to={'/coupons'}><i
                                className="fa fa-barcode text-primary mx-2"/>كوبونات الرصيد</Link>
                        </li> : null}

                        {auth_store.user !== null ? <li className={'nav-item my-2 border-bottom border-1'}>
                            <Link className={'nav-link  text-text'} to={'/bills'}><i
                                className="fa fa-basket-shopping text-primary mx-2"/>المشتريات</Link>
                        </li> : null}


                        {auth_store.user !== null ? <li className={'nav-item my-2 border-bottom border-1'}>
                            <Link className={'nav-link  text-text'} to={'/balances'}><i
                                className="fa fa-dollar-sign text-primary mx-2"/>حركة الرصيد</Link>
                        </li> : null}
                        {auth_store.user !== null ? <li className={'nav-item my-2 border-bottom border-1'}>
                            <Link className={'nav-link  text-text'} to={'/banks'}><i
                                className="fa fa-money-bills text-primary mx-2"/>شحن الرصيد</Link>
                        </li> : null}


                        {auth_store.user != null ? <li className={'nav-item w-100 my-2 border-bottom border-1'} onClick={logOut}>
                            <span className={' nav-link text-text'}><i
                                className="fa fa-right-from-bracket text-primary mx-2"/>خروج</span>
                        </li> : null}
                    </ul>
                </div>
            </div>
            {/* End SideBar */}
            <div className={'wrapper'}>
                {children}
            </div>


            <footer className={'bg-gradient-main border-top-white footer'}>
                <div className="container-fluid">
                    <div className="row justify-content-center align=items-center">
                        <div className="col-6">
                            <p className={'text-black my-3'}> جميع الحقوق محفوظة لموقع <span
                                className={'text-black'}>{auth_store.settings?.name}</span></p>
                        </div>
                        <div className="col-6">
                            <div className="d-flex justify-content-center align-items-center gap-2 my-3">
                                <a href={auth_store.settings?.facebook}>
                                    <i className="fa-brands fa-facebook fa-xl facebook-icon"></i>
                                </a>
                                <a href={auth_store.settings?.telegram}>
                                    <i className="fa-brands fa-telegram fa-xl telegram-icon"></i>
                                </a>
                                <a href={auth_store.settings?.instagram}>
                                    <i className="fa-brands fa-instagram fa-xl instagram-icon"></i>
                                </a>
                                <a href={`https://wa.me/${auth_store.settings?.whatsapp}`}>
                                    <i className="fa-brands fa-whatsapp whatsapp-icon fa-xl"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </section>


    );
}

import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import CardLoadingComponent from "../Loading/CardLoadingComponent";
import NoMoreItemComponent from "../Loading/NoMoreItemComponent";
import CategoryItemComponent from "../Categories/CategoryItemComponent";
import ProductItem from "./ProductItem";

export default function ProductSection() {
    const categoryId = useParams().id
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])
    const [error, setError] = useState('')

    const getProducts = async () => {
        setLoading(true)
        try {
            const response = await baseApi(`/products?category_id=${categoryId}`)
            setProducts(response.data?.products)
        } catch (e) {
            setError(e.response?.data?.msg)
        }
        setLoading(false)

    }
    useEffect(()=>{
        getProducts()
    },[])

    if (loading) {
        return (
            <div className={'container p-5'}>
                <div className="row justify-content-center">
                    <CardLoadingComponent/>
                    <CardLoadingComponent/>
                    <CardLoadingComponent/>
                    <CardLoadingComponent/>
                </div>

            </div>
        )
    } else if (products.length === 0 && error.length === 0) {

        return (
            <NoMoreItemComponent text={'لم يتم إضافة منتجات في القسم '}/>
        )
    } else if (!loading && error.length > 0) {

        return (
            <NoMoreItemComponent text={error}/>
        )
    } else {
        return (
            <div className={'container p-5'}>
                <div className="row justify-content-center">
                    {products?.map((product) => {
                        return <ProductItem product={product} link={`/product-details/${product.id}`}/>
                    })}

                </div>
            </div>
        )

    }

}
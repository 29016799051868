import {useEffect, useState} from "react";
import {useAuthStore} from "../../store/user-store";
import {baseApi} from "../../api/axios";
import LoadingComponent from "../Loading/LoadingComponent";
import CardLoadingComponent from "../Loading/CardLoadingComponent";
import NoMoreItemComponent from "../Loading/NoMoreItemComponent";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

export default function CouponsSection() {
    const [loading, setLoading] = useState(false)
    const [coupons, setCoupons] = useState([])
    const authStore = useAuthStore()
    const redirect = useNavigate()
    useEffect(() => {
        getCoupons()
    }, [])
    const getCoupons = async () => {
        setLoading(true)
        try {
            const response = await baseApi('/coupons')
            if (response.data?.coupons?.length > 0) {
                setCoupons(response.data?.coupons)
            }
            // if(response.data?.coupons)
        } catch (e) {
        }
        setLoading(false)
    }

    const submit = async (amount) => {
        setLoading(true)
        try {
            const data = new FormData()
            data.append('amount', amount)
            const response = await baseApi.post('/getCoupon', data)
            if (response.data?.user) {
                authStore.setUser(response.data?.user)
                localStorage.removeItem('user')
                localStorage.setItem('user', JSON.stringify(response.data?.user))
                redirect('/banks')
            }
        } catch (e) {
            toast.error(e.response.data?.msg)
        }
        setLoading(false)


    }

    if (loading) {
        return <div className={'container'}>
            <div className="row justify-content-center">
                <CardLoadingComponent/>
                <CardLoadingComponent/>
                <CardLoadingComponent/>
            </div>
        </div>
    }
    if (!loading && coupons?.length == 0) {
        return <div className={'container'}>
            <div className="row justify-content-center">
                <NoMoreItemComponent text={'لا وجد كوبونات حالياً'}/>
            </div>
        </div>
    }

    if (!loading && coupons?.length > 0) {
        return <div className={'container'}>
            <div className="row justify-content-center">
                {coupons?.map((el) => {
                    return <div key={el.amount} className="col-md-3 col-6 my-2">
                        <div className="card-coupon">

                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <span className={'title-coupon'}>كوبون</span>
                                <span className={'price-coupon'}>{el.amount} $</span>
                                <button onClick={() => submit(el.amount)} className={'btn btn-buy btn-sm'}>شراء</button>

                            </div>

                        </div>
                    </div>
                })}
            </div>
        </div>
    }
}
import {useEffect, useState} from "react";
import {baseApi} from "../../api/axios";
import {useAuthStore} from "../../store/user-store";
import LoadingComponent from "../Loading/LoadingComponent";
import {toast} from "react-toastify";
import logo from "../../assets/imgs/slide2.png";


export default function ProfileComponent() {
    const authStore = useAuthStore()
    const [scure, setScure] = useState('password')
    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [email, setEmail] = useState(authStore.user.email)
    const [phone, setPhone] = useState(authStore.user.phone)
    const [username, setUsername] = useState(authStore.user.username)
    const [address, setAddress] = useState(authStore.user.address)
    const [name, setName] = useState(authStore.user.name)
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [errors, setErrors] = useState(null)
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const changeScure = () => {
        setScure(scure === 'password' ? 'text' : 'password')
    }
    const getAvatar = (file) => {

    }
    const getProfile = async () => {
        setLoading(true)
        try {
            const response = await baseApi('/profiles')
            if (response.data?.user) {
                authStore.setUser(response.data?.user)
                localStorage.removeItem('user')
                localStorage.setItem('user', JSON.stringify(response.data?.user))
                setPhone(response.data?.user.phone)
                setEmail(response.data?.user.email)
                setName(response.data?.user.name)
                setUsername(response.data?.user.username)
                setAddress(response.data?.user.address)
            }
        } catch (e) {
            console.log(e.response.data)
        }
        setLoading(false)
    }


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setImagePreview(URL.createObjectURL(selectedFile));
            console.log('Selected file:',imagePreview);
        }
    }
    const validateEmail = (text) => {
        return text.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const validate = () => {


        if (name.length === 0) {

            setError( 'يرجى إدخال الاسم بشكل صحيح')
            return  false;
        }
        if (!validateEmail(email)) {
            setError( 'يرجى إدخال البريد الإلكتروني بشكل صحيح')
            return  false;
        }
        if (phone.length === 0) {
            setError('يرجى إدخال رقم الهاتف بشكل صحيح')
            return  false;
        }
        if (password.length > 0 && password.length < 8) {
            setError( 'يرجى إدخال كلمة مرور أكبر من 8 أحرف ')
            return  false;
        }
        if (address.length === 0) {
            setError( 'يرجى إدخال العنوان ')
            return  false;
        }

        return true;
    }

    const onSubmit = async () => {
        setErrors([])
        setLoadingSubmit(true)
        if (validate()) {
            try {
                const data = new FormData()
                data.append('name', name)
                data.append('email', email)
                data.append('phone', phone)
                data.append('address', address)
                data.append('username', username)
                data.append('password', password)
                data.append('_method', 'POST')
                data.append('img',file )
                const response = await baseApi.post('/profiles', data,{
                    headers:{
                        'Content-Type': 'multipart/form-data',
                    }
                })
                if (response.data?.user) {
                    authStore.setUser(response.data?.user)
                    localStorage.removeItem('user')
                    localStorage.setItem('user', JSON.stringify(response.data?.user))
                    toast.success('تم التعديل بنجاح')
                }
            } catch (e) {
                console.log(e.response.data)
                setErrors([e.response.data?.msg])
            }
        }
        setLoadingSubmit(false)
    }

    useEffect(() => {
        getProfile()
    }, [])
    if (loading) {
        return <div className={'container'}>
            <div className="row justify-content-center">
                <div className="col-md-8 text-center">
                    <LoadingComponent/>
                </div>
            </div>
        </div>
    }

    if (!loading && authStore.user != null) {
        return <div className="col-md-8 d-flex justify-content-center align-items-center m-auto">
            <div className=" card-profile">
                <div className="row justify-content-center align-items-center">

                    <h2 className={'text-error fs-4 text-center my-3'}>تعديل الملف الشخصي</h2>
                    <div className="col-md-8">

                        <div className="d-flex flex-column justify-content-center align-items-center card-auth"
                             style={{flex: '1 0 auto'}}>
                            <div className={'logo-container'}>
                                <label htmlFor="avatar" className={'d-inline-block'}>
                                    {imagePreview && (
                                        <img src={imagePreview} alt="Preview" className={'logo-auth'} />
                                    )}
                                    { !imagePreview && authStore.user?.avatar?.length>0 ? <img className={'logo-auth'}
                                                                                               src={authStore.user?.avatar}
                                                                                               alt="asd"/> : null}
                                    {!imagePreview && authStore.user?.avatar?.length===0 ? <img className={'logo-auth'}
                                                                                               src={authStore.settings?.logo ?? logo}
                                                                                               alt="dsa"/> : null}
                                </label></div>

                            <input type="file" accept={'image/jpeg,image/png,image/webp'} id={'avatar'}
                                   className="d-none" onChange={handleFileChange}/>
                            <span className={'text-orange d-inline-block my-2 small'}>إذا كنت لا تريد تعديل كلمة المرور اترك الحقل فارغا</span>
                            <div className="d-flex flex-column flex-grow-1 p-0 m-0 w-100">
                                <span className={'text-text fs-7'}>الاسم</span>
                                <input type="text" onChange={(e) => setName(e.target.value)}
                                       className="input-form input flex-grow-1 my-2" placeholder={'الاسم'}
                                       autoComplete={'off'}
                                       value={name}/>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 p-0 m-0 w-100">
                                <span className={'text-text fs-7'}>البريد الإلكتروني</span>
                                <input type="email" onChange={(e) => setEmail(e.target.value)}
                                       className="input-form input flex-grow-1 my-2" placeholder={'البريد الإلكتروني'}
                                       autoComplete={'new-email'}
                                       value={email}/>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 p-0 m-0 w-100">
                                <span className={'text-text fs-7'}>اسم المستخدم</span>
                                <input type="text" onChange={(e) => setUsername(e.target.value)}
                                       className="input-form input flex-grow-1 my-2" placeholder={'اسم المستخدم'}
                                       autoComplete={'new-username'}
                                       value={username}/>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 p-0 m-0 w-100">
                                <span className={'text-text fs-7'}>الهاتف</span>
                                <input type="text" onChange={(e) => setPhone(e.target.value)}
                                       className="input-form input my-2" placeholder={'رقم الهاتف'} autoComplete={'off'}
                                       value={phone}/>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 p-0 m-0 w-100">
                                <span className={'text-text fs-7'}>العنوان</span>
                                <input type="text" onChange={(e) => setAddress(e.target.value)}
                                       className="input-form input my-2" placeholder={'العنوان'} autoComplete={'off'}
                                       value={address}/>
                            </div>
                            <div className={'position-relative password-container w-100'}>
                                <input type={scure} onChange={(e) => setPassword(e.target.value)}
                                       className="input-form input my-2" placeholder={'كلمة المرور'}
                                       autoComplete={'new-password'}/>
                                <i onClick={changeScure} className="fa fa-eye position-absolute text-primary"></i>
                            </div>
                            {!loadingSubmit ?
                                <button onClick={onSubmit}
                                        className="btn d-block btn-sm btn-buy ">حفظ</button> :
                                <button className="btn d-block btn-sm btn-buy ">جاري المعالجة</button>}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    }
}


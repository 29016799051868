import { useEffect, useState} from "react";

import {useAuthStore} from "../store/user-store";
import {baseApi} from "../api/axios";
const CheckUserProvider = ({ children }) => {
    const authStore = useAuthStore();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            authStore.setUser(JSON.parse(localStorage.getItem('user')));
            baseApi.defaults.headers.common.Authorization = `Bearer ${token}`;
        }
        setLoading(false);
    }, []);

    return loading ? null : children;
};

export default CheckUserProvider;
